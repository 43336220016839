<template>
  <div class="home">
    <header-box :tabbarIndex="1"></header-box>
    <div class="carousel"></div>

    <div class="content">
      <a-carousel :after-change="onChange" autoplay="true">
        <div class="bg">
          <div class="bgContent">
            <div class="title">鑫擎科技值得您的信赖</div>
            <div class="content">
              以物联网变频器+数智平台一体化解决方案 与您携手一起共创未来
            </div>
            <div class="btn">
              <button class="">查看详情</button>
            </div>
          </div>
        </div>
        <div class="bg">
          <div class="bgContent">
            <div class="title">鑫擎科技值得您的信赖</div>
            <div class="content">
              以物联网变频器+数智平台一体化解决方案 与您携手一起共创未来
            </div>
            <div class="btn">
              <button class="">查看详情</button>
            </div>
          </div>
        </div>
        <div class="bg">
          <div class="bgContent">
            <div class="title">鑫擎科技值得您的信赖</div>
            <div class="content">
              以物联网变频器+数智平台一体化解决方案 与您携手一起共创未来
            </div>
            <div class="btn">
              <button class="">查看详情</button>
            </div>
          </div>
        </div>
      </a-carousel>
      <div class="box1">
        <div class="box1_title">提升工业智造 行业数字赋能</div>
        <div class="box1_content">
          <div v-for="(item, index) in useList" class="useBox">
            <img class="useBox_logo" :src="item.img" />
            <div class="useBox_title">{{ item.title }}</div>
            <div class="useBox_content">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box2_title">致力于打造提升工业自动化控制的产品</div>
        <div class="box2_content">
          <div v-for="(item, index) in productList" class="useBox">
            <div class="box_left">
              <div class="useBox_title">{{ item.title }}</div>
              <div class="useBox_content">{{ item.content }}</div>
              <div class="useBox_btn">
                <button class="" @click="knowProduct(item.url)">了解更多</button>
              </div>
            </div>
            <img class="useBox_logo" :src="item.img" />
          </div>
        </div>
      </div>
      <div class="box3">
        <div class="box3_title">广泛应用各领域 为客户提升价值</div>
        <div class="box3_content">
          <div v-for="(item, index) in areaList" class="useBox">
            <img class="useBox_logo" :src="item.img" />
            <div class="useBox_bt">
              <div class="useBox_title">{{ item.title }}</div>
              <div class="useBox_content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="box4_title">产品资源</div>
        <div class="table">
          <div class="header">
            <div class="down">
              <img src="../assets/home/down.png" />
              <span>资源下载</span>
            </div>
            <div class="downBtn">批量下载</div>
          </div>
          <div class="table_content">
            <div v-for="(item, index) in tablelist" :key="index" class="liList">
              <a-checkbox v-model:checked="item.checked">{{ item.name }}</a-checkbox>
              <div>
                <span>预览</span>
                <span>下载</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <botton-navigation></botton-navigation>
  </div>
</template>

<script setup>
import headerBox from "../components/hed.vue";
import BottonNavigation from "../components/BottonNavigation.vue";
import router from "@/router";
import { ref } from "vue";

function knowProduct(url) {
  console.log(url);
  router.push(url);
}
const useList = ref([
  {
    title: "数据实时采集",
    content: "通过多种手段在短时间内自动收集和处理大量数据的过程。",
    img: require("../assets/home/box1_logo1.png"),
  },
  {
    title: "客户运营无感知",
    content: "通过监控客户感知发现常见的用户痛点并改善客户体验流程。",
    img: require("../assets/home/box1_logo2.png"),
  },
  {
    title: "指令远程下发",
    content: "通过某种通信协议向远程设备发送命令或控制信息。",
    img: require("../assets/home/box1_logo3.png"),
  },
  {
    title: "故障远程诊断",
    content: "对设备当前的故障状态数据进行回收然后在后台对数据进行详细分析。",
    img: require("../assets/home/box1_logo4.png"),
  },
  {
    title: "减少设备故障率",
    content: "为了降低设备故障率采取一些有效的措施来降低故障率。",
    img: require("../assets/home/box1_logo5.png"),
  },
  {
    title: "故障提前预警",
    content: "通过对设备进行实时监测和分析及时发现异常情况并发出预警。",
    img: require("../assets/home/box1_logo6.png"),
  },
  {
    title: "提高能源利用效率",
    content: "提高技术效率、实施电气化、行为改变和节约能源等。",
    img: require("../assets/home/box1_logo7.png"),
  },
]);
const productList = ref([
  {
    title: "明星产品",
    content: "引领行业潮流的璀璨之星",
    img: require("../assets/home/pic1.png"),
    url: "/product/skt800",
  },
  {
    title: "变频器",
    content: "驱动领导者 您的首选合作伙伴",
    img: require("../assets/home/pic2.png"),
    url: "/product/skt200",

  },
  {
    title: "专用变频器",
    content: "性能稳定",
    img: require("../assets/home/pic3.png"),
    url: "/product/sh600",

  },
  {
    title: "软起动器",
    content: "稳定可靠",
    img: require("../assets/home/pic4.png"),
    url: "/product/sktr1",

  },
]);
const areaList = ref([
  {
    title: "智能家居",
    content: "掌控家中的一切，轻松享受智能科技带来的便利",
    img: require("../assets/home/area1.png"),
  },
  {
    title: "智能交通",
    content: "让你告别拥堵和纠结，轻松驾驶畅行无忧",
    img: require("../assets/home/area2.png"),
  },
  {
    title: "智能制造",
    content: "探索智能制造的奥秘，开创制造业的崭新篇章",
    img: require("../assets/home/area3.png"),
  },
  {
    title: "智能能源",
    content: "拥抱智能能源，实现能源利用的高效化、智能化",
    img: require("../assets/home/area4.png"),
  },
  {
    title: "智能医疗",
    content: "融入智能医疗，感受科技带来的健康新体验",
    img: require("../assets/home/area5.png"),
  },
  {
    title: "智能农业",
    content: "走进智能农业，探索农业发展新路径",
    img: require("../assets/home/area6.png"),
  },
]);
const tablelist = ref([
  {
    name: "SKTR1 系列软起动器在线目录",
    checked: false,
  },
  {
    name: "SKTR2 系列软起动器在线目录",
    checked: false,
  },
  {
    name: "SKT100 系列变频器目录",
    checked: false,
  },
  {
    name: "SKT200 系列高性能矢量变频器",
    checked: false,
  },
]);
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  .box1 {
    width: 100%;
    height: 400pt;
    // background: red;
    .box1_title {
      width: 100%;
      height: 20%;
      text-align: center;
      font-size: 20pt;
      font-weight: 500;
      // 居中显示
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box1_content {
      width: 60%;
      height: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      // color: #585858;

      //   background: green;
      .useBox {
        width: 18%;
        height: 33%;
        background: #f5f8fd;
        margin: 10pt;
        border-radius: 10pt;
        // 左对齐
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10pt;
        // align-items: center;

        .useBox_logo {
          width: 15%;
          height: 20%;
        }
        .useBox_title {
          width: 90%;
          height: 20%;
          text-align: center;
          font-size: 15pt;
          font-weight: 500;
          // 文字居左
          text-align: left;
        }
        .useBox_content {
          width: 90%;
          height: 40%;
          text-align: center;
          font-size: 10pt;
          text-align: left;
        }
      }
    }
  }
  .box2 {
    width: 100%;
    height: 600pt;
    background: #fafafa;
    .box2_title {
      width: 100%;
      height: 20%;
      text-align: center;
      font-size: 20pt;
      font-weight: 500;
      // 居中显示
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box2_content {
      width: 60%;
      height: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      //   background: green;
      .useBox {
        width: 40%;
        height: 33%;
        background: #fff;
        margin: 10pt;
        border-radius: 10pt;
        padding: 20pt;
        display: flex;
        // align-items: center;
        .box_left {
          width: 48%;
          height: 100%;
          .useBox_title {
            width: 90%;
            height: 20%;
            text-align: center;
            font-size: 15pt;
            font-weight: 600;
            text-align: left;
          }
          .useBox_content {
            width: 90%;
            height: 10%;
            color: #585858;
            font-size: 10pt;
          }
          .useBox_btn {
            width: 90%;
            height: 12%;
            margin-top: 50%;
            button {
              width: 40%;
              height: 100%;
              background: #005a9e;
              font-size: 8pt;
              color: #fff;
              border: none;
              border-radius: 10pt;
              cursor: pointer;
            }
          }
        }
        .useBox_logo {
          width: 50%;
          height: 90%;
        }
      }
    }
  }
  .box3 {
    width: 100%;
    height: 700pt;
    background: #fff;
    .box3_title {
      width: 100%;
      height: 20%;
      text-align: center;
      font-size: 20pt;
      font-weight: 500;
      // 居中显示
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box3_content {
      width: 68%;
      height: 70%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      margin: auto;
      color: #585858;
      .useBox {
        width: 30%;
        height: 33%;
        .useBox_logo {
          width: 90%;
          height: 90%;
        }
        .useBox_bt {
          width: 80%;
          height: 30%;
          background-color: #f5f8fd;
          padding: 5%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .useBox_title {
            width: 100%;
            height: 10%;
            text-align: center;
            font-size: 15pt;
            font-weight: 500;
            // 文字居左
            text-align: left;
            color: #000;
          }
          .useBox_content {
            width: 100%;
            height: 10%;
            text-align: center;
            font-size: 10pt;
            text-align: left;
          }
        }
      }
    }
  }
  .box4 {
    width: 100%;
    height: 400pt;
    background: #fafafa;
    .box4_title {
      width: 100%;
      height: 20%;
      text-align: center;
      font-size: 20pt;
      font-weight: 500;
      // 居中显示
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .table {
      width: 60%;
      height: 70%;
      //   background: red;
      // 居中
      margin: auto;
      .header {
        width: 100%;
        height: 15%;
        // background: green;
        display: flex;
        // 子元素垂直居中
        align-items: center;
        .down {
          width: 87%;
          height: 100%;
          display: flex;
          align-items: center;
          img {
            width: 3%;
            height: 45%;
            margin-right: 15pt;
          }
          span {
            font-size: 15pt;
            color: #000;
            font-weight: 600;
          }
        }
        .downBtn {
          font-size: 15pt;
          color: #00689e;
          font-weight: 600;
        }
      }
      .table_content {
        width: 100%;
        height: 85%;
        // background: #fff;
        .liList {
          width: 100%;
          height: 10%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f5f8fd;
          padding: 10pt;
          .ant-checkbox-wrapper {
            width: 80%;
            height: 100%;
            display: flex;
            align-items: center;
            .ant-checkbox {
              margin-right: 10pt;
            }
          }
          div {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            span {
              font-size: 12pt;
              color: #000;
              cursor: pointer;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  :deep(.slick-slide) {
    text-align: center;
    height: 460px;
    line-height: 160px;
    overflow: hidden;
    background-image: url(@/assets/home/bg1.png);   
  }
  :deep(.slick-slide h3) {
    color: #fff;
  }
  .bg {
    position: relative;
    width: 100%;
    height: 460px;
    .bgContent {
      position: absolute;
      top: 20%;
      left: 15%;
      width: 40%;
      height: 40%;

      .title {
        top: 0;
        position: absolute;
        left: 15%;

        font-size: 30pt;
        font-weight: 500;
        color: #000;
        text-align: center;
      }
      .content {
        top: 20%;
        position: absolute;
        left: 15%;
        font-size: 10pt;
        font-weight: 500;
        color: #000;
        text-align: center;
      }
      .btn {
        button {
          top: 100%;
          position: absolute;
          left: 15%;
          width: 40%;
          height: 20%;
          background: #005a9e;
          color: #fff;
          border: 1px solid #005a9e;
          cursor: pointer;
        }
        button:hover {
          border: 3px solid #005a9e;
        }
      }
    }
  }
}
</style>