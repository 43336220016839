import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../pages/home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product/index',
    name: 'ProductIndex',
    component: () => import('../pages/product/index.vue')
  },
  {
    path: '/product/skt800',
    name: 'skt800',
    component: () => import('../pages/product/skt800.vue')
  },
  {
    path: '/product/sh120',
    name: 'sh120',
    component: () => import('../pages/product/sh120.vue')
  },
  {
    path: '/product/sh600',
    name: 'sh600',
    component: () => import('../pages/product/sh600.vue')
  },
  {
    path: '/product/sv10',
    name: 'sv10',
    component: () => import('../pages/product/sv10.vue')
  },
  {
    path: '/product/skt100',
    name: 'skt100',
    component: () => import('../pages/product/skt100.vue')
  },
  {
    path: '/product/skt200',
    name: 'skt200',
    component: () => import('../pages/product/skt200.vue')
  },
  {
    path: '/product/sktr1',
    name: 'sktr1',
    component: () => import('../pages/product/sktr1.vue')
  },
  {
    path: '/product/sktr2',
    name: 'sktr2',
    component: () => import('../pages/product/sktr2.vue')
  },
  {
    path: '/successCases/index',
    name: 'successCases',
    component: () => import('../pages/successCases/index.vue')
  },
  {
    path: '/serve/index',
    name: 'serve',
    component: () => import('../pages/serve/index.vue')
  },
  {
    path: '/xinqing/index',
    name: 'xinqing',
    component: () => import('../pages/xinqing/index.vue')
  },
  {
    path: '/news/index',
    name: 'news',
    component: () => import('../pages/news/index.vue')
  },
  {
    path: '/news/showNew',
    name: 'showNew',
    component: () => import('../pages/news/showNew.vue')
  },
  {
    path: '/contact/index',
    name: 'contact',
    component: () => import('../pages/contact/index.vue')
  },
  {
    path: '/successCases/sh120',
    name: 'sh120',
    component: () => import('../pages/successCases/sh120.vue')
  },
  {
    path: '/successCases/sh120Weight',
    name: 'sh120Weight',
    component: () => import('../pages/successCases/sh120Weight.vue')
  },
  {
    path: '/successCases/sh120drive',
    name: 'sh120drive',
    component: () => import('../pages/successCases/sh120drive.vue')
  },
  {
    path: '/successCases/sh120centrifuge',
    name: 'sh120centrifuge',
    component: () => import('../pages/successCases/sh120centrifuge.vue')
  },
  {
    path: '/successCases/sh120ball',
    name: 'sh120ball',
    component: () => import('../pages/successCases/sh120ball.vue')
  },
  {
    path: '/successCases/sh120washing',
    name: 'successCasesProduct',
    component: () => import('../pages/successCases/sh120washing.vue')
  },





]

const router = createRouter({
  history: createWebHashHistory(),  
  routes
})

export default router
