    <!-- 写一个网站头部导航栏组件，左边是网站的logo，右边是导航链接，导航链接的数据是从父组件传递过来的，父组件的数据如下：首页、新闻、关于我们、联系我们 -->
<template>
  <div>
    <div class="bc">
      <div class="logoTitle"></div>
      <div class="navList">
        <div v-for="(item, index) in navList" :key="index" class="titleBox">
          <div
            :class="tabbarIndex == index + 1 ? 'titleHover' : 'title'"
            @click="goRouter(index)"
          >
            {{ item }}
          </div>
          <div class="btline" v-if="tabbarIndex == index + 1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

    <script setup>
import router from "@/router";
import { ref } from "vue";
// 父组件传递过来的数据
const props = defineProps({
  tabbarIndex: Number,
});
const navList = ref([
  "首页",
  "产品",
  "成功案例",
  "支持与服务",
  "了解鑫擎",
  "新闻中心",
  "联系我们",
]);

function goRouter(index) {
  console.log(index);
  switch (index) {
    case 0:
      router.push("/");
      break;
    case 1:
      router.push("/product/index");
      break;
    case 2:
      router.push("/successCases/index");
      break;
    case 3:
      router.push("/serve/index");
      break;
    case 4:
      router.push("/xinqing/index");
      break;
    case 5:
      router.push("/news/index");
      break;
    case 6:
      router.push("/contact/index");
      break;
  }
}
</script>

<style lang="less"  scoped>
.bc {
  display: flex;
  //   background-color: red;
  width: 100%;
  height: 41pt;
  z-index: 999;
  .logoTitle {
    width: 25%;
    height: 100%;
    // background-color: green;
    background-image: url(../assets/home/logo.png);
    background-size: 40% 50%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .navList {
    width: 65%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // background-color: yellow;
    .titleBox:hover {
      .title {
        color: #005a9e;
        font-size: 16pt;
      }
    }
    .titleBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      cursor: pointer;
      position: relative;
      .title {
        font-size: 15pt;
        color: #000;
        font-weight: 500;
      }
      .titleHover {
        font-size: 15pt;
        font-weight: 500;
        color: #005a9e;
      }
      .btline {
        width: 100%;
        height: 7pt;
        background-color: #005a9e;
        //   圆角
        border-radius: 6pt;
        // 固定在底部
        position: absolute;
        bottom: 0;
      }
    }
  }
}
</style>
    
    