<template>
  <div class="box">
    <div class="top">
      <div class="logoTitle">鑫擎科技值得您的信赖</div>
      <button class="btn" @click="serve">服务与支持</button>
    </div>
    <div class="bottom">
      <div class="box1">
        <div v-for="(item, index) in navigationList" :key="index">
          <div class="box1_title">{{ item.title }}</div>
          <div
            v-for="(item, index) in item.list"
            :key="index"
            class="box1_smtitle"
            @click="goRouter(item.url)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="box2_1">
          <div class="box2_1_title">关注鑫擎</div>
          <div class="box2_1_content">
            关注鑫擎公众号或下载鑫擎APP，关注鑫擎，随时随地为您服务
          </div>
        </div>
        <div class="box2_2">
          <img src="../assets/bottomNavi/1.png" />
          <img src="../assets/bottomNavi/2.png" />
          <img src="../assets/bottomNavi/3.png" />
        </div>
        <div class="box2_3">联系我们: 19906608650</div>
      </div>
      <div class="box3">
        <div
          v-for="(item, index) in companyInfoList"
          :key="index"
          class="box3_companyInfo"
        >
          <div class="box3_title">{{ item.title }}</div>
          <div class="box3_content">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "@/router";

function goRouter(url) {
  console.log(url);
  router.push(url);
}
function serve() {
  router.push("/serve/index");
}
const companyInfoList = ref([
  {
    title: "公司电话：19906608650",
    content: "宁波鑫擎科技有限公司",
  },
  {
    title: "邮箱：zhangyufeng@starking-tech.com",
    content: "版权所有方案备2023004958号",
  },
  {
    title: "网址：www.starking-tech.com",
    content: "",
  },
]);
const navigationList = ref([
  {
    title: "产品",
    list: [
      { name: "软启动器", url: "/product/sktr1" },
      { name: "变频器", url: "/product/skt200" },
      { name: "变频驱动器", url: "/product/sh600" },
    ],
  },
  {
    title: "支持与服务",
    list: [
      { name: "公司动态", url: "" },
      { name: "发现并修理故障", url: "" },
      { name: "成功案例", url: "/successCases/index" },
      { name: "资源下载", url: "" },
    ],
  },
  {
    title: "新闻中心",
    list: [{ name: "新闻中心", url: "/news/index" }],
  },
  {
    title: "加入我们",
    list: [
      { name: "加入我们", url: "/contact/index" },
      { name: "了解鑫擎", url: "/xinqing/index" },
    ],
  },
  {
    title: "联系我们",
    list: [
      { name: "售前服务", url: "/contact/index" },
      { name: "售后服务", url: "/contact/index" },
    ],
  },
]);
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 408pt;
  background-color: #ececec;
  background-image: url(../assets/bottomNavi/bottomBIM.png);
  background-size: 100% 30%;
  background-repeat: no-repeat;
  position: relative;
  //  背景图添加蒙版
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
  }
  //   固定在底部
  //   position: fixed;
  //   bottom: 0;
  .top {
    width: 40%;
    height: 30%;
    // background: red;
    position: absolute;
    left: 16%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .logoTitle {
      font-size: 30pt;
      font-weight: 500;
    }
    .btn {
      width: 26%;
      height: 30%;
      background: #005a9e;
      color: white;
      font-size: 14pt;
      font-weight: 500;
      border: none;
      cursor: pointer;
      border: 2px solid #ececec;
    }
    .btn:hover {
      //  动画边框
      border: none;
    }
  }
  .bottom {
    width: 80%;
    height: 70%;
    // background: yellow;
    position: absolute;
    left: 16%;
    bottom: 0;
    .box1 {
      width: 70%;
      height: 50%;
      //   background: red;
      float: left;
      display: flex;
      justify-content: space-between;
      .box1_title {
        font-weight: 500;
        font-size: 17pt;
        width: 100%;
        height: 20%;
      }
      .box1_smtitle {
        width: 100%;
        height: 20%;
        span {
          font-size: 10pt;
        }
        span:hover {
          //    下边框
          border-bottom: 2px solid #000;

          cursor: pointer;
        }
      }
    }
    .box2 {
      width: 25%;
      height: 90%;
      //   background: green;
      float: left;
      position: absolute;
      right: 0;
      .box2_1 {
        width: 80%;
        height: 30%;
        .box2_1_title {
          font-weight: 500;
          font-size: 17pt;
          width: 100%;
          height: 30%;
          margin-bottom: 3%;
        }
        .box2_1_content {
          width: 100%;
          height: 20%;
          font-size: 10pt;
        }
      }
      .box2_2 {
        width: 100%;
        height: 40%;
        display: flex;
        img {
          width: 40%;
          height: 90%;
          //   background: red;
          margin-right: 1%;
        }
      }
      .box2_3 {
        width: 100%;
        height: 10%;
        font-size: 10pt;
      }
    }
    .box3 {
      width: 70%;
      height: 30%;
      //   background: blue;
      float: left;
      position: absolute;
      bottom: 20%;
      display: flex;
      justify-content: space-between;
      .box3_companyInfo {
        width: 30%;
        height: 100%;
        .box3_title {
          font-weight: 500;
          font-size: 10pt;
          width: 100%;
          height: 30%;
        }
        .box3_content {
          width: 100%;
          height: 20%;
          font-size: 10pt;
        }
      }
    }
  }
}
</style>